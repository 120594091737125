<template>
  <div
    style="width: 100%; height: 100%; text-align: center; padding-top: 100px"
    v-if="state.loaderEnabled"
  >
    <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
  </div>
  <transition name="fade">
    <div class="card" v-if="!state.loaderEnabled">
      <div class="card-header border-0 pt-6">
        <SearchAndFilter
          :listInitial="state.initialMyList"
          :filters="tableFilters"
          @returnSearch="state.myList = $event"
          @returnLoadingDatatable="state.loadingDatatable = $event"
          ExcelFilename="Stillnetwork_Commandes"
          :ExcelFormat="ExcelFormat"
        ></SearchAndFilter>
      </div>
      <div class="card-body pt-0">
        <div
          v-if="state.myList.length == 0"
          class="
            alert
            bg-light-primary
            alert-primary
            d-flex
            align-items-center
            p-5
            mb-10
          "
        >
          <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
            <inline-svg src="media/icons/duotune/general/gen048.svg" />
          </span>
          <div class="d-flex flex-column">
            <h4 class="mb-1 text-dark">Aucune commande</h4>
            <span
              >Il n'existe aucune commande à afficher correspondant à vos
              critères de recherche.</span
            >
          </div>
        </div>
        <Datatable
          v-if="state.myList && state.myList.length > 0"
          :table-data="state.myList"
          :table-header="tableHeader"
          :enable-items-per-page-dropdown="true"
          empty-table-text="Aucune commande à afficher."
          :key="state.loadingDatatable"
        >
          <template v-slot:cell-pro_nom_projet="{ row: data }">
            <div style="margin-left: 15px" class="text-gray-800">
              <strong>{{ data.pro_nom_projet }}</strong>
              <br />
              <div style="margin-left: 15px" class="text-gray-400">
                <span class="w-150px fs-8">N° Commande Client</span>
                <span style="margin-left: 15px" class="text-gray-600">{{
                  data.num_cmd_client
                }}</span>
              </div>
              <div style="margin-left: 15px" class="text-gray-400">
                <span class="w-150px fs-8">N° Offre Stillnetwork</span>
                <span style="margin-left: 23px" class="text-gray-600">
                  <b>
                    <router-link :to="returnUrlOffre(data.tof_code)">
                      {{ data.tof_code }}
                    </router-link>
                  </b>
                </span>
              </div>
              <div style="margin-left: 15px" class="text-info">
                <span class="fs-8">
                  <b>{{ data.con_prenom }} {{ data.con_nom }}</b>
                </span>
              </div>
            </div>
          </template>
          <template v-slot:cell-num_cmd_client="{ row: data }">
            <div>
              <strong>{{ data.num_cmd_client }}</strong>
            </div>
          </template>
          <template v-slot:cell-toc_date_creation="{ row: data }">
            {{ formatDate(data.toc_date_creation) }}
          </template>
          <template v-slot:cell-tfa_code="{ row: data }">
            <span class="text-primary">{{ data.tfa_code }}</span>
          </template>
          <template v-slot:cell-tof_total_ht="{ row: data }">
            <div>
              <span class="text-gray-400"
                >{{ formatMoney(data.tof_total_ht) }} {{ data.dev_sigle }}</span
              >
              <br />
              <strong class="text-gray-700"
                >{{ formatMoney(data.tof_total_ttc) }}
                {{ data.dev_sigle }}</strong
              >
            </div>
          </template>
          <template v-slot:cell-date_ldc="{ row: data }">
            <span v-if="!data.date_ldc">
              <div class="badge badge-light-danger w-175px bt-5 text-danger">
                Date non communiquée
              </div></span
            >
            <div v-else>
              <el-popover
                placement="top-start"
                title="Date de livraison"
                :width="300"
                trigger="hover"
                :content="formatHoverDateD(data)"
              >
                <template #reference>
                  <span
                    class="badge badge-white w-175px fs-6 text-gray-900"
                    style="cursor: help"
                    >{{ formatDate(data.date_ldc) }}

                    <span
                      class="svg-icon svg-icon-3 svg-icon-success ms-1"
                      v-if="
                        data.date_old_ldc && data.date_old_ldc > data.date_ldc
                      "
                    >
                      <inline-svg src="media/icons/duotune/arrows/arr066.svg" />
                    </span>

                    <span
                      class="svg-icon svg-icon-3 svg-icon-danger ms-1"
                      v-if="
                        data.date_old_ldc && data.date_old_ldc < data.date_ldc
                      "
                    >
                      <inline-svg src="media/icons/duotune/arrows/arr065.svg" />
                    </span>
                  </span>
                </template>
              </el-popover>
              <br />
              <div class="badge badge-light-warning text-gray-600 w-175px bt-5">
                Mise à jour {{ formatDateAgo(data.fraicheur) }}
              </div>
            </div>
          </template>
          <template v-slot:cell-fraicheur="{ row: data }">
            {{ formatDateAgo(data.fraicheur) }}
          </template>
          <template v-slot:cell-toc_etat_support="{ row: data }">
            <div class="d-flex flex-column">
              <li
                v-if="data.toc_etat_matos > 0"
                class="d-flex align-items-center text-dark text-uppercase fs-8"
              >
                Equipements
              </li>
              <li
                v-if="data.toc_etat_integration > 0"
                class="d-flex align-items-center text-dark text-uppercase fs-8"
              >
                Prestation
              </li>
              <li
                v-if="data.toc_etat_support > 0"
                class="d-flex align-items-center text-dark text-uppercase fs-8"
              >
                Support / Abonnement
              </li>
            </div>
          </template>
          <template v-slot:cell-etat_txt="{ row: data }">
            <span
              style="cursor: default"
              :class="styleBtnStatus(data)"
              class="mt-2 mb-2 btn bg-white btn-sm btn-text-dark"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg :src="styleImgStatus(data)" />
              </span>
              {{ data.etat_txt }}
            </span>
          </template>
          <template v-slot:cell-toc_seq="{ row: data }">
            <router-link
              :to="returnUrlCommande(data.toc_seq)"
              class="
                btn btn-sm btn-active-primary
                me-2
                mb-2
                btn-bg-white btn-text-dark
              "
            >
              <span class="svg-icon svg-icon-1 svg-icon-primary">
                <inline-svg
                  src="media/icons/duotune/general/gen004.svg"
                /> </span
              >Détail de la commande
            </router-link>
          </template>
        </Datatable>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as moment from "moment";
import { useRouter } from "vue-router";
import SearchAndFilter from "@/views/still/search_and_filters.vue";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Liste des commandes en cours",
  components: {
    Datatable,
    SearchAndFilter,
  },
  setup() {
    moment.default.locale("fr");
    const router = useRouter();
    const state = reactive({
      loaderEnabled: true,
      myList: [],
      drawer: ref(false),
      sourcePDF: "",
      mFact: "",
      initialMyList: [],
      loadingDatatable: 0,
      search: "",
    });
    const tableHeader = ref([
      {
        name: "Projet",
        key: "pro_nom_projet",
        sortable: false,
      },
      {
        name: "Date commande",
        key: "toc_date_creation",
        sortable: true,
      },
      {
        name: "Livraison estimée",
        key: "date_ldc",
        sortable: true,
      },
      {
        name: "Montants HT / TTC",
        key: "tof_total_ht",
        sortable: true,
      },
      {
        name: "Statut",
        key: "etat_txt",
        sortable: true,
      },
      {
        name: "Contenu",
        key: "toc_etat_support",
        sortable: true,
      },
      {
        name: "Afficher",
        key: "toc_seq",
        sortable: true,
      },
    ]);

    const tableFilters = ref([
      {
        name: "Demandeurs",
        key: "con_seq",
        label: "con_prenom_nom",
      },
      {
        name: "Status",
        key: "etat_txt",
        label: "etat_txt",
        default : ["En cours de traitement","Partiellement livrée"]
      },
    ]);

    const ExcelFormat = ref({
      Projet: "pro_nom_projet",
      "Numero commande client": "num_cmd_client",
      "Numéro Offre Stillnetwork": "tof_code",
      Date: "toc_date_creation",
      Demandeur: "con_prenom_nom",
      Statut: "etat_txt",
      "Montant HT": "tof_total_ht",
      "Date LDC": "date_ldc",
      Fraicheur: "fraicheur",
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const formatMoney = (n) => {
      if (!n) n = 0;
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const styleBtnStatus = (data) => {
      if (data.etat_txt == "En cours de traitement")
        return "btn-icon-dark bg-white";
      if (data.etat_txt == "Partiellement livrée")
        return "btn-icon-warning bg-white";
      if (data.etat_txt == "Commande livrée")
        return "btn-icon-success bg-white";
    };

    const styleImgStatus = (data) => {
      if (data.etat_txt == "En cours de traitement")
        return "media/icons/duotune/arrows/arr069.svg";
      if (data.etat_txt == "Partiellement livrée")
        return "media/icons/duotune/arrows/arr084.svg";
      if (data.etat_txt == "Commande livrée")
        return "media/icons/duotune/arrows/arr085.svg";
    };

    const returnUrlOffre = (data) => {
      return `/stillboard/offres/${data}`;
    };

    const returnUrlCommande = (data) => {
      return `/commande/${data}/livraisons`;
    };

    const formatHoverDateD = (data) => {
      if (!data.date_old_ldc) {
        return `La date de livraison n'a connue aucune variation depuis le ${formatDate(
          data.toc_date_creation
        )} `;
      }
      return `${formatDateAgo(
        data.fraicheur
      )}, la date de livraison est passée du ${formatDate(
        data.date_old_ldc
      )} au ${formatDate(data.date_ldc)}`;
    };

    const affDetail = async () => {
      state.drawer = true;
    };

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Liste des commandes en cours", [
        "Home",
        "Board Stillnetwork",
      ]);

      let myList = await getAxios("/getCommandes/-1");
      state.initialMyList = myList.results;
      state.myList = myList.results;
      // console.log(myList.results);

      for (
        let index = 0, l = state.initialMyList.length;
        index < l;
        index += 1
      ) {
        const item: any = state.initialMyList[index];
        item.etat_txt = "En cours de traitement";

        if (
          item.toc_etat_livraison_min == 1 &&
          item.toc_etat_livraison_max == 2
        ) {
          item.etat_txt = "Partiellement livrée";
        }

        if (
          item.toc_etat_livraison_min == 2 &&
          item.toc_etat_livraison_max == 2
        ) {
          item.etat_txt = "Commande livrée";
        }
      }

      state.loaderEnabled = false;
      if (router.currentRoute.value.params.code) {
        state.search = router.currentRoute.value.params.code as string;
        affDetail();
      }
    });

    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      formatMoney,
      styleBtnStatus,
      styleImgStatus,
      affDetail,
      formatHoverDateD,
      returnUrlOffre,
      returnUrlCommande,
      tableFilters,
      ExcelFormat,
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  // console.log(response.data);
  return response.data;
}
</script>
